function is_touch_device() {
  return 'ontouchstart' in window        // works on most browsers 
      || navigator.maxTouchPoints;       // works on IE10/11 and Surface
};

if (is_touch_device()){
	document.documentElement.classList.add('touch')
}

var nav_el = document.querySelector('.collection_nav');

var Navigation = (function(){
	var init = function(){
		if (!nav_el){
			return;
		}
		var links = nav_el.querySelectorAll('.collection_nav_item a');
	
		var checks = document.querySelectorAll('header .check, .collection_nav_item a');

	
		[].forEach.call(links, function(link){
			console.log(link);
			link.addEventListener('mouseover', function(){
				img = link.nextElementSibling;
				if (link.classList.contains('check')){
					checkFunc(img);
				}else if (link.classList.contains('black')){
					link.classList.add('dark');
					[].forEach.call(checks, function(check){check.classList.add('dark');})
				}else if (link.classList.contains('white')){
					console.log('white');
					link.classList.add('light');
					[].forEach.call(checks, function(check){check.classList.add('light');})
				}
			});
			link.addEventListener('mousemove', function(){
				img = link.nextElementSibling;
				if (link.classList.contains('check')){
					checkFunc(img);
				}else if (link.classList.contains('black')){
					link.classList.add('dark');
					[].forEach.call(checks, function(check){check.classList.add('dark');})
				}else if (link.classList.contains('white')){
					link.classList.add('light');
					[].forEach.call(checks, function(check){check.classList.add('light');})
				}
			});

			link.addEventListener('mouseout', function(){
				checkRevert();
				link.classList.remove('light');
				link.classList.remove('dark');
				[].forEach.call(checks, function(check){
					check.classList.remove('dark');
					check.classList.remove('light');
				})

			});
		});
		nav_el.addEventListener('mouseout', function(){
			checkRevert();
		});
	};
	return {
		init: init
	}
})();


document.addEventListener('DOMContentLoaded', function(){
	Navigation.init();
})