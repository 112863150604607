var check = false;

var checkFunc = function(img){
    check = true;
    console.log(img);
	getImageLightness(img, checkCallback);
} 


var checkCallback = function(brightness){
	console.log(brightness, brightness > 50);
	var checks = document.querySelectorAll('.check, .collection_nav_item a');
	if (brightness < 100){
		[].forEach.call(checks, function(check){
			check.classList.add('light');
		});
	}
};
var checkRevert = function(){
    check = false;
	var checks = document.querySelectorAll('.check');
	[].forEach.call(checks, function(check){
		check.classList.remove('dark');
		check.classList.remove('light');
	});
}

var getImageLightness = function(imgEl, callback) {
    
    
    var blockSize = 5, // only visit every 5 pixels
        defaultRGB = {r:0,g:0,b:0}, // for non-supporting envs
        canvas = document.createElement('canvas'),
        context = canvas.getContext && canvas.getContext('2d'),
        data, width, height,
        i = -4,
        length,
        rgb = {r:0,g:0,b:0},
        count = 0;
        
    if (!context) {
        return defaultRGB;
    }
    
    height = canvas.height = imgEl.naturalHeight || imgEl.offsetHeight || imgEl.height;
    width = canvas.width = imgEl.naturalWidth || imgEl.offsetWidth || imgEl.width;
    
    context.drawImage(imgEl, 0, 0);
    
    try {
        data = context.getImageData(0, 0, width, height);
    } catch(e) {
        /* security error, img on diff domain */alert('x');
        return defaultRGB;
    }
    
    length = data.data.length;
    
    while ( (i += blockSize * 4) < length ) {
        ++count;
        rgb.r += data.data[i];
        rgb.g += data.data[i+1];
        rgb.b += data.data[i+2];
    }
    
    // ~~ used to floor values
    rgb.r = ~~(rgb.r/count);
    rgb.g = ~~(rgb.g/count);
    rgb.b = ~~(rgb.b/count);
    
    callback( (rgb.r + rgb.g + rgb.b)/3);
    
}
